<template>
  <div class="log-management-page">
    <el-row>
      <el-col :span="24">
        <el-card shadow="never">
          <el-form :inline="true" :model="searchFrom" class="demo-form-inline searchFrom" size="small">
            <el-form-item :label="$t('searchForm.operator')">
              <el-input
                v-model="searchFrom.operateUser"
                :clearable="true"
                :placeholder="$t('searchForm.operatorPlaceholder')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('searchForm.time')">
              <el-date-picker
                v-model="datePick"
                type="daterange"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
                range-separator="~"
                :start-placeholder="$t('searchForm.startTime')"
                :end-placeholder="$t('searchForm.endTime')"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" @click="search">{{ $t('btn.search') }}</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>

    <div class="table-block">
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{ background: '#f7f9fc', border: 'none', color: '#252526' }"
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column type="index" :label="$t('table.order')" width="100" align="center" />
        <el-table-column prop="operateUser" :label="$t('table.operator')" width="150" align="center" />
        <el-table-column prop="operateMatters" :label="$t('table.OperationMatters')" width="150" align="center" />
        <el-table-column prop="dataInfo" :label="$t('table.operationDetails')" align="center" />
        <el-table-column prop="operateTime" :label="$t('table.OperatingTime')" width="200" align="center" />
      </el-table>
      <pagination
        @changePage="changePage"
        :total="total"
        :page.sync="searchFrom.pageNo"
        :size.sync="searchFrom.pageSize"
      />
    </div>
  </div>
</template>
<script>
import { logList } from 'src/api/index'
import pagination from 'src/components/pagination'
export default {
  name: 'role-management',
  components: { pagination },
  data() {
    let minTime = null
    let maxTime = null
    return {
      pickerOptions: {
        onPick(time) {
          // 如果选择了只选择了一个时间
          let timeRange = 14 * 24 * 60 * 60 * 1000 // 15天 选中日期+14
          if (!time.maxDate) {
            minTime = time.minDate.getTime() - timeRange // 最小时间
            maxTime = time.minDate.getTime() + timeRange // 最大时间
            // 如果选了两个时间，那就清空本次范围判断数据，以备重选
          } else {
            minTime = maxTime = null
          }
        },
        disabledDate(time) {
          // onPick后触发 返回false表示该日期禁选
          if (minTime && maxTime) {
            return time.getTime() < minTime || time.getTime() > maxTime
          }
        },
      },
      datePick: [],
      searchFrom: {
        operateUser: '',
        startTime: '',
        endTime: '',
        pageNo: 1,
        pageSize: 10,
      },
      loading: false,
      tableData: [],
      total: 0,
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      logList(this.searchFrom).then(res => {
        this.loading = false
        this.tableData = res.data
        this.total = res.total
      })
    },

    changePage(pageParams) {
      const { pageNo, pageSize } = pageParams
      this.searchFrom = { ...this.searchFrom, pageNo, pageSize }
      this.getList()
    },

    search() {
      if (this.datePick != null && this.datePick.length) {
        this.searchFrom.startTime = this.datePick[0] + ' 00:00:00'
        this.searchFrom.endTime = this.datePick[1] + ' 23:59:59'
      } else {
        this.searchFrom.startTime = ''
        this.searchFrom.endTime = ''
      }

      this.searchFrom = { ...this.searchFrom, pageNo: 1 }
      this.getList()
    },
  },
}
</script>
<style lang="scss" scoped>
.log-management-page {
  background: transparent;
}
</style>
